import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/content/es-ist-mir-peinlich-der-apotheke-nach-einem-durchfallmittel-zu-fragen-was-kann-ich-tun"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">&nbsp;</div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1 />
                    <div className="std-site">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2></h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline"></p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left"></div>
                        <div className="right">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <div
                                  id="faqA17"
                                  style={{
                                    display: "block"
                                  }}
                                >
                                  <p className="faqAnsFirst">
                                    Durchfall kann auf mehr als eine Art
                                    unangenehm sein – besonders wenn in der
                                    Apotheke noch mehrere Leute hinter Ihnen
                                    warten, wenn Sie an der Reihe sind. Denken
                                    Sie einfach daran, dass Durchfall eine der
                                    häufigsten Beschwerden ist, mit denen
                                    Apotheker zu tun haben. Sie sind dafür
                                    ausgebildet, Ihr Problem zu verstehen und
                                    professionellen Rat zu erteilen und sie
                                    können Ihnen die für Sie passende
                                    Behandlung, z.B. mit&nbsp;
                                    <a href="/imodium-produkte/">
                                      IMODIUM®
                                    </a>
                                    ,&nbsp;anbieten. Wenn es Ihnen besonders
                                    peinlich ist, können Sie vielleicht auch
                                    darum bitten, den Apotheker unter vier Augen
                                    zu sprechen.
                                  </p>
                                </div>
                                <p>&nbsp;</p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine
            Anwendung finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
