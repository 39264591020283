/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/GTM";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="de"
            xmlns="http://www.w3.org/1999/xhtml"
            xmlLang="de"
            version="XHTML+RDFa 1.0"
            dir="ltr"
            fakexmlnscontent="http://purl.org/rss/1.0/modules/content/"
            fakexmlnsdc="http://purl.org/dc/terms/"
            fakexmlnsfoaf="http://xmlns.com/foaf/0.1/"
            fakexmlnsog="http://ogp.me/ns#"
            fakexmlnsrdfs="http://www.w3.org/2000/01/rdf-schema#"
            fakexmlnssioc="http://rdfs.org/sioc/ns#"
            fakexmlnssioct="http://rdfs.org/sioc/types#"
            fakexmlnsskos="http://www.w3.org/2004/02/skos/core#"
            fakexmlnsxsd="http://www.w3.org/2001/XMLSchema#"
          />
          <body className="html not-front not-logged-in no-sidebars page-node page-node- page-node-73 node-type-faq" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <link
            rel="shortcut icon"
            href="/misc/gatsby-icon.png"
          />
          <link
            rel="canonical"
            href="/content/es-ist-mir-peinlich-der-apotheke-nach-einem-durchfallmittel-zu-fragen-was-kann-ich-tun/"
          />
          <meta name="Generator" content="Drupal 7 (http://drupal.org)" />
          <title>
            Es ist mir peinlich, in der Apotheke nach einem Durchfallmittel zu
            fragen. Was kann ich tun? | IMODIUM<sup>®</sup>
          </title>
          <style
            type="text/css"
            media="all"
            cssText='
@import url("/modules/system/system.base.css?qbzjxo");
@import url("/modules/system/system.menus.css?qbzjxo");
@import url("/modules/system/system.messages.css?qbzjxo");
@import url("/modules/system/system.theme.css?qbzjxo");
'
          />

          <style
            type="text/css"
            media="all"
            cssText='
@import url("/modules/comment/comment.css?qbzjxo");
@import url("/modules/field/theme/field.css?qbzjxo");
@import url("/modules/node/node.css?qbzjxo");
@import url("/modules/search/search.css?qbzjxo");
@import url("/modules/user/user.css?qbzjxo");
@import url("/sites/all/modules/contrib/views/css/views.css?qbzjxo");
'
          />
          <style
            type="text/css"
            media="all"
            cssText='
@import url("/sites/all/modules/contrib/ctools/css/ctools.css?qbzjxo");
@import url("/sites/all/modules/contrib/lexicon/css/lexicon.css?qbzjxo");
@import url("/sites/all/modules/contrib/panels/css/panels.css?qbzjxo");
@import url("/sites/all/modules/contrib/faq/faq.css?qbzjxo");
@import url("/sites/all/modules/contrib/eu_cookie_compliance/css/eu_cookie_compliance.css?qbzjxo");
'
          />
          <style
            type="text/css"
            media="all"
            cssText="
<!--/*--><![CDATA[/*><!--*/
#sliding-popup.sliding-popup-bottom,#sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner,.eu-cookie-withdraw-tab{background:#0779bf;}#sliding-popup.sliding-popup-bottom.eu-cookie-withdraw-wrapper{background:transparent}#sliding-popup .popup-content #popup-text h1,#sliding-popup .popup-content #popup-text h2,#sliding-popup .popup-content #popup-text h3,#sliding-popup .popup-content #popup-text p,.eu-cookie-compliance-more-button,.eu-cookie-compliance-secondary-button,.eu-cookie-withdraw-tab{color:#fff !important;}.eu-cookie-withdraw-tab{border-color:#fff;}

/*]]>*/-->
"
          />
          <style
            type="text/css"
            media="all"
            cssText='
@import url("/sites/all/themes/imodium_basic/../imodium/style.css?qbzjxo");
@import url("/sites/all/themes/imodium_basic/../imodium/page.css?qbzjxo");
'
          />

          <script
            type="text/javascript"
            src="/sites/all/themes/imodium_basic/js/jquery-1.12.0.min.js?v=1.12.0"
          />
          <script type="text/javascript" src="/misc/jquery.once.js?v=1.2" />
          <script type="text/javascript" src="/misc/drupal.js?qbzjxo" />
          <script
            type="text/javascript"
            src="/sites/all/modules/contrib/eu_cookie_compliance/js/jquery.cookie-1.4.1.min.js?v=1.4.1"
          />
          <script
            type="text/javascript"
            src="/sites/all/themes/imodium_basic/../imodium/main.js?qbzjxo"
          />
          <script
            type="text/javascript"
            src="/sites/all/themes/imodium_basic/../imodium/jquery.simplemodal.1.4.4.min.js?qbzjxo"
          />

          
          <link rel="alternate" hrefLang="de-AT" href="http://imodium.at" />
          <link rel="alternate" hrefLang="de-DE" href="http://imodium.de" />
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
